.contact-form-v1 {
	padding-bottom: 62px !important;
	background: #e5e1db;
	box-shadow: 0 0 15px 3px rgb(0 0 0 / 30%);
	border-radius: 0;

	.checkbox label {
		vertical-align: inherit;
		color: var(--color-navy);
	}

	.red {
		color: var(--color-red);
	}

	.form-group>label {
		color: var(--color-navy);
	}

	.checkbox-success input[type=checkbox]:checked+label:after {
		color: var(--color-navy);
	}

	.form-control {
		background: transparent !important;
		border: 1px solid #a39c8a;
		color: var(--color-navy) !important;
		border-radius: 0 !important;

		option {
			color: #000;
		}
	}


	.checkbox label:before {
		border-radius: 0 !important;
		background: transparent !important;
		top: 2px;
		border: 1px solid #a39c8a !important;
	}

	.checkbox label:after {
		top: 2px;
	}
}