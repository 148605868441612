.contact-sidebar-v1 {
	background-color: transparent;
	border: 1px solid #a39c8a;

	.contact-map {
		width: 100%;
		height: 300px;
	}

	h3 {
		color: var(--color-gray-dark);
		border-bottom: 1px dashed #a39c8a;
		width: 100%;
		display: block;
		margin: 0 0 15px;
		padding-bottom: 15px;
		text-decoration: none !important;
	}

	.contact-bl {
		display: flex;
		margin-bottom: 15px;
		position: relative;
		padding-left: 40px;
		min-height: 40px;
		align-items: center;
		font-size: var(--font-size-md);
		color: var(--color-gray-dark);

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: var(--color-navy);
			text-decoration: none;

			&:hover {
				color: var(--color-gold);
			}
		}


		i {
			color: var(--color-white);
			background-color: var(--color-gold);
			padding: 5px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			box-shadow: 0 0 10px rgba(0, 0, 0, .18);
			font-size: 16px;
		}


	}

	.openings {
		div {
			margin-top: 10px;
			font-size: var(--font-size-md);
			color: var(--color-gray-dark);
		}
	}
}