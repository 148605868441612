.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);

	@media (max-height: 600px) {
		min-height: 680px;

	}

	.owl-carousel {
		z-index: unset;
	}

	.header-badge {
		padding: 0;
		position: absolute;
		z-index: 1;
		right: 20px;
		bottom: 20px;

		@media (max-width:1600px) {
			bottom: unset;
			top: 170px;
		}

		/*@media (max-width:1499px) {
			bottom: unset;
			top: 135px;
		}*/

		@media (max-width: 767px) {
			bottom: unset;
			top: 130px;
		}

		/* @media all and (min-width: 992px) and (max-width: 1200px) {
			top: 130px;
		} */

		/* span {
			background: linear-gradient(45deg, #0fd64f, #f6ef42 60%);
			color: red;
			font-size: 14px;
			font-weight: 600;
			height: 90px;
			display: -webkit-box;
			display: flex;
			-webkit-box-align: center;
			-webkit-box-pack: center;
			border-radius: 100%;
			padding: 5px;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			width: 90px;
			text-align: center;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			line-height: normal;
			position: absolute;
			z-index: 1;
			right: 20px;
			top: 130px;

			@media all and (min-width: 768px) and (max-width: 1200px) {
				height: 120px;
				width: 120px;
				font-size: 16px;
				right: 25px;
			}
		} */
		img {
			width: 100%;
			max-width: 130px;
			height: auto;

			@media (max-width: 767px) {
				max-width: 100px;
			}
		}
	}

	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);


		.Slider-3-1 {
			img {
				object-position: top;
			}
		}

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);

			@media (max-height: 600px) {
				min-height: 680px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 65vh;
		font-family: 'Poppins', cursive;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-width: 1650px) {
			top: 60vh;
		}

		@media (max-width: 1499px) {
			top: 64vh;
		}

		@media (max-width: 1200px) {
			top: unset;
			bottom: 225px;
		}

		@media (max-width: 992px) {
			top: unset;
			bottom: 330px;
		}

		@media (max-width: 767px) {
			top: unset;
			bottom: 270px;
		}

		@media (max-height: 600px) {
			top: 240px;

		}



		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 70px;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
				font-family: 'GrandSlang Roman';
				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 70px;
				}

				@media (max-width: 1499px) {
					font-size: 56px;
				}

				@media (max-width: 1200px) {
					font-size: 45px;
				}

				@media (max-width: 992px) {
					font-size: 30px;
					line-height: normal;
				}

				@media (max-width: 767px) {
					font-size: 32px;
					line-height: normal;
				}

				@media (max-width: 575px) {
					font-size: 26px;
					line-height: normal;
				}

				@media (max-height: 600px) {
					font-size: 22px;
					line-height: normal;
				}

				span {
					font-size: 36px;
					font-weight: 600;

					a {
						color: #fff;
					}

					@media (max-width: 1200px) {
						font-size: 30px;
					}

					@media (max-width: 992px) {
						font-size: 20px;
					}

					@media (max-width: 767px) {
						font-size: 18px;
					}

					@media (max-height: 600px) {
						font-size: 18px;
						line-height: normal;
					}
				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 32px;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
				font-family: 'GrandSlang Roman';

				@media (min-width: 1600px) {
					font-size: 32px;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 26px;
				}

				@media (max-width: 767px) {
					font-size: 24px;
					line-height: normal;
				}

				@media (max-width: 575px) {
					font-size: 18px;
					line-height: normal;
					display: inline-block;
				}

				@media (max-height: 600px) {
					font-size: 16px;
					line-height: normal;
				}
			}
		}

	}
}