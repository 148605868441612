.unit-description-v1 {
	.description {
		margin-bottom: 30px;

		p {
			color: #192f3f;
		}
	}

	.show-more {
		font-size: var(--font-size-md);
	}
}