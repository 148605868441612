.static-view {
	margin-top: var(--page-margin-top);
	background: var(--color-sand);

	h1 {
		/*	color: #fff;*/
		margin: 0 0 20px 0;
	}

	.angebote-bl {

		margin: 0 0 30px;

		img {
			width: 100%;
		}
	}

	h2 {

		border-color: #fff;
		margin: 0 0 30px 0;
		font-size: 26px;
	}

	h3 {

		margin: 0 0 15px 0;
		font-family: "Raleway", sans-serif;
		font-weight: 600;
	}

	p {
		color: var(--color-gray-dark);
	}

	a {
		color: var(--color-navy);
		text-decoration: underline;

		&:hover {
			color: var(--color-gold);
		}
	}

	h4 {
		line-height: normal;
	}

	ul {
		list-style: disc;
		padding-left: 20px;

		li {
			color: var(--color-gray-dark);

		}
	}

	.static-sidebar {

		@media all and (min-width: 992px) and (max-width: 1199px) {
			-webkit-box-flex: 0;
			flex: 0 0 30%;
			max-width: 30%;
		}

		.static-sidebar-inner {
			border: 1px solid #192f3f;
			padding: 15px 15px 0 15px;

			@media (max-width: 991px) {
				display: none;
			}

			h3 {
				color: #192f3f;

				a {
					color: #192f3f;
					border-bottom: 1px dashed #192f3f;
					width: 100%;
					display: block;
					margin: 0 0 15px 0;
					padding-bottom: 15px;
					text-decoration: none !important;
				}
			}

			ul {
				margin: 0 0 30px;
				list-style: none;
				padding: 0;

				li {
					a {
						position: relative;
						color: var(--color-navy);
						display: flex;
						align-items: center;
						margin: 0 0 5px 0;
						text-decoration: none !important;
						font-size: 16px;

						&:hover {
							color: var(--color-gold);
						}

						&:before {
							font-family: Font Awesome\ 5 Free;
							content: "\f054";
							font-weight: 900;
							font-size: 12px;
							margin: 0 10px 0 0;
						}
					}
				}
			}
		}
	}

	&.ferienhauser-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/parallax-1.jpg);
			background-position: 0 45% !important;
		}
	}

	.static-right {
		@media all and (min-width: 992px) and (max-width: 1199px) {
			-webkit-box-flex: 0;
			flex: 0 0 70%;
			max-width: 70%;
		}

		.static-right-inner {
			h1 {
				color: #192f3f;
				margin: 0 0 20px 0;
			}

			h2 {
				color: #192f3f;
				border-color: #192f3f;
				margin: 0 0 30px 0;
				font-size: 26px;
			}

			h3 {
				color: #192f3f;
				margin: 0 0 15px 0;
			}

			p {
				color: #192f3f;
			}

			a {
				color: var(--color-navy);
				text-decoration: underline;

				&:hover {
					color: var(--color-gold);
				}
			}

			ul {
				list-style: disc;
				padding-left: 20px;

				li {
					color: #192f3f;

				}
			}

			table {
				color: #192f3f;
				margin-bottom: 20px;
			}

			.Bildergalerie-row {
				margin-left: -10px;
				margin-right: -10px;

				@media (max-width: 767px) {
					margin-left: -7px;
					margin-right: -7px;
				}

			}



			#imagegallery {
				display: flex;
				flex-wrap: wrap;
				height: auto !important;

				.imagegallery {
					position: static !important;
					width: 33.33% !important;
					height: 200px !important;
					margin: 0 0 20px 0;
					padding: 0 10px;

					@media (max-width: 1499px) {
						height: 170px !important;
					}

					@media (max-width: 1199px) {
						height: 140px !important;
					}

					@media (max-width: 767px) {
						height: 110px !important;
						margin: 0 0 15px;
						padding: 0 7px;
					}

					@media (max-width: 550px) {
						width: 50% !important;
					}

					img {
						position: static !important;
						margin: 0 !important;
						width: 100% !important;
						height: 100% !important;
						object-fit: cover;
					}
				}
			}
		}

		.aktivitaeten-boxes {
			margin-top: 50px;

			.aktivitaeten-box {
				display: flex;
				flex-wrap: wrap;
				background: #e5e1db;
				box-shadow: 0 0 15px 3px rgb(0 0 0/30%);
				margin: 0 0 30px 0;

				.image {
					width: 30%;

					@media (max-width: 767px) {
						width: 100%;
					}


					img {
						width: 100%;

						@media all and (min-width: 768px) and (max-width: 1199px) {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.info {
					width: 70%;
					padding: 0 30px 0 30px;
					position: relative;

					@media (max-width: 1199px) {
						padding: 0 30px 30px 30px;
					}

					@media (max-width: 991px) {
						padding: 0 20px 20px 20px;
					}

					@media (max-width: 767px) {
						width: 100%;
						padding: 0 15px 15px 15px;
					}

					h4 {
						font-size: 30px;
						color: var(--color-navy);
						margin: 20px 0 20px 0;

						@media (max-width: 767px) {
							font-size: 25px;
						}
					}

					p {
						color: var(--color-navy);
						margin: 0 0 20px 0;
					}

					h6 {
						margin: 0;
						text-align: right;

						a.btn {
							position: absolute;
							bottom: 20px;
							right: 20px;
							color: #ffffff !important;
							text-decoration: none;
							min-width: unset;

							@media (max-width: 1199px) {
								position: static;
							}

							i {
								font-size: 14px;
							}

						}
					}
				}
			}
		}
	}

	&.ferienhauser-v1 {
		.img-content {
			img {
				width: 100%;
				height: 250px;
				object-fit: cover;

				@media all and (min-width: 768px) and (max-width: 991px) {
					height: 170px;
				}
			}
		}

		.ferienhauser-box {
			margin: 0 0 30px 0;

			.info {
				border: 1px solid var(--color-sand-dark);
				background-color: var(--color-sand-dark);
				padding: 15px;

				h4 {
					margin: 0 0 5px 0;

					a {
						color: var(--color-navy);
						text-decoration: none;
						font-size: 18px;
					}
				}

				p {
					color: var(--color-navy);
					display: flex;
					align-items: center;
					margin: 0;
					font-size: 15px;

					i {
						margin: 0 7px 0 0;
					}
				}
			}
		}
	}
}