.search-result-header-v1 {
	border-radius: 0px;
	padding: 20px 20px;
	width: 100%;
	background: #dbd6d0;
	/*box-shadow: 0 0 10px #000;*/

	.sort-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #192f3f;

		.sort-by {

			display: flex;
			align-items: center;
			color: #fff;

			a {
				color: #192f3f;
				background: transparent;
				text-decoration: none !important;

				&:hover {
					color: #fff !important;
				}
			}

			.list-group-item {
				padding: 10px 15px;
				background: transparent;
			}

			>.box {
				border: none !important;
				background: #3e4037;
				box-shadow: 0 0 10px #000;
				border-radius: 0 !important;

				.arrow {
					z-index: 2;
				}

				>.body {
					padding: 0;

					.list-group {
						margin-bottom: 0;
					}
				}
			}

			strong {
				width: 45px;
				height: 45px;
				box-shadow: none;
				text-align: center;
				line-height: 45px;
				font-size: 28px;
				border-radius: 0px;
				border: 1px solid #192f3f;
				margin: 0 0;
				cursor: pointer;

				.fa-sort-amount-down {
					color: #192f3f;
				}
			}

			.sort-item {
				color: var(--color-grey-dark);
				font-size: 12px;
				margin: 5px 0;
				background-color: var(--color-white);
				padding: 10px 12px;
				border: 1px solid #dcdcdc;
				margin-left: -1px;
				display: inline-block;
			}

		}
	}
}