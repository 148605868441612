.searchbar-v1 {
	position: absolute;
	top: 85vh;
	width: 100%;

	@media (max-width: 1200px) {
		top: unset;
		bottom: 50px;
	}

	@media (max-width: 992px) {
		top: unset;
		bottom: 50px;
	}

	@media (max-width: 767px) {
		top: unset;
		bottom: 0;
	}

	@media (max-width: 576px) {
		padding: 0px 10px;
	}

	@media (max-height: 600px) {
		top: 380px;

	}

	.down-arrow {
		text-align: center;
		color: #fff;
		font-size: 30px;
		padding: 10px 0 0 0;

		a {
			color: #fff;
		}
	}

	.title {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;
	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--white);
		border-bottom: 0px solid #eaeaea;
		flex-flow: wrap;
		/*box-shadow: 0 2px 12px rgb(254 154 0 / 25%);*/
		background: var(--color-sand);
		/* background: linear-gradient(0deg, rgba(25, 47, 64, 1) 0%, rgba(76, 96, 111, 1) 100%); */
		/*max-width: 1050px;*/
		margin: 0 auto;
		width: calc(100% - 100px);
		border-left: 1px solid #5e5e5e;

		@media (max-width:992px) {
			width: 100%;
			border: 0;
		}

		.form-flex {
			width: 31%;
			padding: 0;
			border-right: 1px solid #5e5e5e;

			&.v2 {
				width: 45%;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid var(--color-navy);
				border-right: none;
				padding: 5px 0;

				&.v2 {
					width: 100%;
				}
			}

			.box {}

			.fa {
				/*	position: absolute;
				right: 15px;
				top: 26px;*/
				color: var(--color-navy);
				font-size: 18px;
				margin-right: 10px;

				@media (max-width: 992px) {
					top: 10px;
				}
			}

			.form-control {
				width: auto;
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				height: auto;
				min-height: 75px;
				/*padding-right: 35px !important;*/
				padding: 0px 15px;
				color: #192f3f;
				color: var(--color-navy);
				text-align: center;
				font-size: 18px;

				@media (max-width: 992px) {
					font-size: 16px;
					min-height: 40px;
				}
			}

			>label {

				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.datepicker-trigger {
			.asd__wrapper {
				@media (max-width: 767px) {
					margin: 0 auto;
				}
			}
		}

		.form-btn {
			width: 7%;
			display: flow-root;
			webkit-box-align: center;
			-webkit-box-align: center;
			align-items: center;
			padding-right: 0;
			padding-left: 0;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
			}

			.btn {
				width: 101%;
				height: 50px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 20px;
				padding: 0;
				background: transparent;
				border: none;
				color: var(--color-gold);

				&:hover {
					color: var(--color-navy);
				}

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-search-bl {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-direction: column;
			width: 100%;
		}

		.form-label {
			display: flex;
			width: 100px;
			min-height: 75px;
			background: var(--color-sand);
			justify-content: center;
			align-items: center;
			text-align: center;

			@media (max-width:992px) {
				display: none;
				width: 100%;
			}

			label {
				margin: 0;
				font-weight: 600;
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}