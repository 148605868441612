.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/parallax-1.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	.parallax-box {
		/*background-color: #2e517bc9;*/
		padding: 50px;
		min-height: 350px;

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-white);
			font-size: 22px;

			@media (max-width: 767px) {
				font-size: 18px;
			}
		}
	}

}