.unit-title-v1 {
	h1 {
		color: var(--color-gold);
	}

	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}
}