/* .navbar-v1.affix, */
.navbar-v1 {

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background: var(--color-sand);

	/*background: -moz-linear-gradient(top, #3e4037 0%, #030303 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3e4037), color-stop(100%, #030303));
	background: -webkit-linear-gradient(top, #3e4037 0%, #030303 100%);
	background: -o-linear-gradient(top, #3e4037 0%, #030303 100%);
	background: -ms-linear-gradient(top, #3e4037 0%, #030303 100%);
	background: linear-gradient(to bottom, #3e4037 0%, #030303 100%);*/
	.f-menu {
		.dropdown-menu {
			min-width: 180px;
		}
	}

	@media (max-width:1200px) {
		position: absolute;
	}

	.input-search-unit {
		padding: 5px;
		width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;
			background: transparent;
			border-color: var(--main-nav-font-color);
		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
			background-color: var(--main-nav-font-color);
			border: 1px solid var(--main-nav-font-color) !important;
		}
	}

	.fav-count {
		position: relative;

		a {
			i {
				color: var(--color-gold);
			}

			&:hover {
				i {
					color: var(--color-navy);
				}
			}
		}

		.sub-count {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 4px;
			right: 0.5vW;
			width: 18px;
			height: 18px;
			background: var(--color-secondary);
			color: var(--color-white);
			text-align: center;
			font-size: 12px;
			line-height: 18px;

			@media (max-width:767px) {
				right: 0;
			}
		}
	}

	.search-unit-ul {
		position: absolute;
		right: 0;
		background-color: var(--color-grey-light);
		bottom: -80px;
		max-width: 310px;
		padding: 8px;
	}

	.top-nav {
		padding: 0px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			/*	@media (max-width:1650px) {
				margin-right: -100px;
			}

			@media (max-width:1350px) {
				margin-right: 0;
			}*/

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;

						@media (max-width: 767px) {
							font-size: 18px;
							margin: 0;
						}

						@media all and (min-width: 1201px) and (max-width: 1499px) {
							font-size: 18px;
						}
					}
				}
			}

			.lang-dropdown {

				.dropdown-menu {
					min-width: 65px;
					padding: 0;
					margin-top: 5px;

					@media (max-width: 1200px) {
						margin-top: 25px;
						position: absolute !important;
					}
				}

				.dropdown-toggle {
					cursor: pointer;

					span {
						display: inline-flex;
						cursor: pointer;
						align-items: center;

						img {
							margin: 0px 10px 0 10px;
						}
					}
				}

				.sub-nav {

					ul {
						padding: 0;
						display: block;

						li:first-child {}

						li {
							display: unset;

							a {
								position: relative;
								width: 100%;
								font-size: 14px;
								display: flex;
								text-transform: uppercase;
								align-items: center;
								padding: 7px 10px;
								background: var(--color-sand);
								color: var(--top-nav-font-color) !important;
								border: 1px solid var(--color-sand);


								.flag {
									background-image: url(RESOURCE/img/languages.png);
									display: block;
									width: 16px;
									height: 16px;
									background-position: 0 0;
									margin: -1px 0 0 5px;
								}

								&.de {
									.flag {
										background-position: 0 -16px;
									}
								}

								&.en {
									.flag {
										background-position: 0 0;
									}
								}

							}
						}
					}
				}
			}
		}

		.fav-count {
			position: relative;

			a {
				margin-right: 10px !important;

				@media (max-width: 767px) {
					margin-right: 15px !important;
				}
			}

			.sub-count {
				-moz-border-radius: 50%;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: -5px;
				right: 15px;
				width: 18px;
				height: 18px;
				background: var(--color-navy);
				color: var(--color-white);
				text-align: center;
				font-size: 12px;
				line-height: 18px;


				@media (max-width: 1200px) {
					right: 0;
				}

				@media (max-width: 767px) {
					right: -10px;
				}

				@media all and (min-width: 1201px) and (max-width: 1499px) {
					right: 5px;
				}

				@media all and (min-width: 1500px) and (max-width: 1650px) {
					right: 3px;
				}
			}
		}

	}



	.header-main {
		margin-right: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		@media (max-width:1650px) {
			margin-right: 0;
		}

		@media (max-width:1550px) {
			margin-right: 0;
		}

		@media (max-width:1350px) {
			margin-right: 0;
		}

		.header-logo {
			/* position: absolute;
			top: 5px; */
			position: relative;
			z-index: 20;
			margin-left: 0;
			width: 30%;

			@media (max-width:1650px) {
				margin-left: 0px;
			}

			@media (max-width:1550px) {
				margin-left: 0px;
			}

			@media (max-width:1350px) {
				margin-left: 0;
			}

			@media all and (min-width: 1201px) and (max-width: 1499px) {
				width: 28%;
			}

			.logo {
				max-width: 455px;
				height: auto;
				width: 100%;
				margin-bottom: 0;
				padding-top: 15px;
				padding-left: 20px;
				padding-bottom: 15px;

				@media (max-width:1650px) {
					max-width: 400px;
				}

				@media (max-width:1499px) {
					max-width: 350px;
				}

				@media (max-width:1200px) {
					max-width: 340px;
					margin-bottom: -50px;
					margin-left: 0;
					padding: 0;
				}

				@media (max-width:767px) {
					max-width: 285px;
				}

				@media (max-width: 374px) {
					max-width: 250px;
				}
			}

		}

		.nav-row {
			width: 40%;

			@media (max-width: 1200px) {
				width: 100%;
			}

			@media all and (min-width: 1201px) and (max-width: 1499px) {
				width: 44%;
			}

			.row {
				@media (max-width: 1200px) {
					margin: 0;
				}
			}
		}

		.header-right {
			width: 30%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			@media (max-width: 1200px) {
				width: 100%;
				position: absolute;
				right: 0;
				top: 0px;
				margin-top: 0;
			}

			@media all and (min-width: 1201px) and (max-width: 1499px) {
				width: 28%;
			}

			.header-badge {
				padding: 0px 20px;

				@media (max-width: 1200px) {
					display: none;
				}

				@media all and (min-width: 992px) and (max-width: 1199px) {
					padding: 7px 10px;
				}

				@media all and (min-width: 1200px) and (max-width: 1499px) {
					padding: 7px 30px 7px 20px;
				}

				@media all and (min-width: 1500px) and (max-width: 1650px) {
					padding: 7px 30px 7px 20px;
				}

				@media all and (min-width: 1651px) and (max-width: 1750px) {
					padding: 7px 30px 7px 20px;
				}

				img {
					width: 100%;
					max-width: 100px;
					height: auto;
				}

				span {
					/* background: linear-gradient(45deg, #0fd64f, #f6ef42 60%);
					color: red;
					font-size: 18px;
					font-weight: 600;
					display: flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: center;
					justify-content: center;
					border-radius: 100%;
					padding: 5px;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					width: 140px;
					height: 140px;
					text-align: center;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					line-height: normal; */

					@media all and (min-width: 992px) and (max-width: 1200px) {
						width: 100px;
						height: 100px;
						font-size: 14px;
					}

					@media all and (min-width: 1201px) and (max-width: 1499px) {
						width: 110px;
						height: 110px;
						font-size: 14px;
					}

				}
			}

			.icons-nav {
				margin-top: 0;
				display: flex;
				align-items: center;
				margin-bottom: 0;
				position: relative;

				@media all and (min-width: 1200px) and (max-width: 1499px) {
					padding-left: 0;
				}

				li {
					&.btn-call {
						.tele-box {
							visibility: hidden;
							opacity: 0;
						}

						a {
							&:hover {
								color: var(--color-grey-dark);
							}
						}
					}

					&:hover {
						.tele-box {
							visibility: visible;
							opacity: 1;
							color: var(--color-grey-dark);
						}
					}

					a {
						padding: 0 1.2vW;
						font-size: var(--main-nav-font-size);
						pointer-events: auto;
						color: #192f3f !important;
						display: flex;
						align-items: center;
						position: relative;


						@media all and (min-width: 1500px) and (max-width: 1599px) {
							padding: 0 10px;
						}

						/*@media all and (min-width: 1600px) and (max-width: 1750px) {
							padding: 0 15px;
						}*/

						&:hover {
							color: var(--color-gold) !important;
						}

						@media (max-width:767px) {
							margin: 0 5px;
							font-size: 18px;
						}

						@media all and (min-width: 768px) and (max-width: 1200px) {
							padding: 0 10px;
						}

						@media all and (min-width: 1201px) and (max-width: 1499px) {
							padding: 0 14px;
							font-size: 20px;
						}
					}
				}
			}
		}

		.main-menu {


			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 100%;
				background-color: var(--main-nav-mob-menu-bg-color);
				margin-top: 0;
				border-top: 1px solid var(--color-navy);
				padding-left: 10px;
				padding-right: 10px;

			}

			@media (max-width:1200px) {
				top: 110px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				top: 124px;
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					@media (max-width:1200px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}

					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 15px 1.2vW;
						line-height: 1;
						display: block;
						font-weight: 600;
						font-family: GrandSlang Roman;
						display: flex;
						align-items: center;

						@media (max-width:1199px) {
							font-size: 18px;
						}

						@media all and (min-width: 1201px) and (max-width: 1499px) {
							font-size: 19px;
							padding: 15px 15px;
						}

						@media all and (min-width: 1500px) and (max-width: 1599px) {
							font-size: 20px;
						}

						@media all and (min-width: 1600px) and (max-width: 1750px) {
							font-size: 22px;
						}

						/* @media (max-width:1100px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						} */


						&:hover {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}


						&.dropdown-toggle {
							&:after {
								@media (min-width: 1201px) {
									display: none;
								}
							}
						}


					}

					&:hover {
						>a {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;

						}

						.dropdown-toggle {
							color: var(--main-nav-font-color-hover);
						}

						@media (min-width:1200px) {
							.dropdown-menu {
								display: block;
							}
						}

					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}
				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}

		.nav-row {

			@media all and (min-width: 992px) and (max-width: 1199px) {
				min-height: 120px !important;
			}

			.col-12 {
				display: flex;
				justify-content: center;
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 210px;
		margin-top: -1px;
		background: var(--body-color);
		box-shadow: 0 0 10px #000;
	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;
			padding-right: 10px;

			li {
				a {
					padding: 10px 0;
					color: var(--color-gray-dark) !important;
					display: block;
					font-size: 18px;
					font-weight: 600;
					font-family: GrandSlang Roman;

					@media (max-width:1200px) {
						padding: 7px;
						text-transform: initial;
						font-size: 16px;
					}

					@media all and (min-width: 1201px) and (max-width: 1499px) {
						font-size: 16px;
					}

					&:hover {
						color: var(--color-gold) !important;
						text-decoration: none;

					}
				}

			}
		}


	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--main-nav-font-color);
		font-size: 30px;

		@media all and (min-width: 992px) and (max-width: 1199px) {
			right: 150px;
		}

	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}


	@media screen and (max-width: 1200px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			/*	.input-search-unit {
				display: none;
			}*/
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 110px !important;
			margin: 0 !important;
		}

		.icon {
			display: block;
			bottom: 10px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;


			@media (max-width:767px) {
				top: 64px;
				bottom: auto;
				z-index: 99;
				margin-right: -80px;
			}

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}

		.header-main {

			.header-logo {
				/*position: static;
				text-align: center;
				margin-left: 40px;*/

				position: absolute;
				margin: 0 auto;
				left: 0;
				right: 0;
				text-align: left;
				top: 10px;
				z-index: unset;
				width: 100%;
				padding-left: 15px !important;
			}

			.icon {
				position: absolute;
				right: 10px;
				top: 60px;
			}
		}
	}

	@media screen and (max-width: 767px) {

		.top-nav {
			text-align: center;

			/*.input-search-unit {
				display: none;
			}*/
		}

		.header-main {

			.header-logo {
				/*position: static;
				text-align: center;
				margin-left: 40px;*/

				position: absolute;
				margin: 0 auto;
				left: 0;
				right: 0;
				text-align: left;
				top: 27px;
				z-index: unset;
				width: 100%;
				padding-left: 15px !important;
			}

			.icon {
				position: absolute;
				right: 10px;
				top: 60px;
			}
		}

	}

	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			/*.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}*/

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1100px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:767px) {
							padding: 5px 8px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}



		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							color: var(--main-nav-font-color-affix-top);

							@media (max-width: 1200px) {
								color: (--top-nav-font-color-affix-top);
								font-size: 16px;
							}


							&:hover {
								color: var(--main-nav-font-color-affix-top-hover);
								background-color: var(--main-nav-bg-affix-top-hover);
								text-decoration: none;
							}



						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}



	}

	.vo-nav-bar {
		/* @media (min-width:1200px) {
			min-height: 150px;
		} */

	}
}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}