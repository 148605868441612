.booking-v1 {

	.big {
		font-size: 22px !important;
		font-weight: 800 !important;

	}

	.booking-view {
		padding-top: 120px;
		color: var(--color-navy);
	}

	.required::after {
		content: "*";
		vertical-align: super;
		font-size: var(--font-size-sm);
	}

	.form-control {
		background: transparent !important;
		border: 1px solid #a39c8a;
		color: var(--color-navy) !important;
		border-radius: 0 !important;

	}

	.checkbox label:before {
		border-radius: 0 !important;
		background: transparent !important;
		top: 3px;
		border: 1px solid #a39c8a !important;
	}

	.checkbox label:after {
		top: 3px;
		color: var(--color-navy) !important;
	}

	select {
		option {
			color: #000;
		}
	}

	.label {
		display: inline-block;
	}

	.cart-details {
		padding: 14px;
		background-color: transparent;
		border: 1px solid #a39c8a;

		.unit-details {
			margin: 20px 0;
			color: var(--color-grey-dark);

			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;
				color: var(--color-navy);

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-gold);
				}
			}
		}
	}

	.price-details-btn {
		color: #a39c8a !important;
		text-decoration: underline;

		&:hover {
			color: #fff !important;
		}
	}

	.pd-form {
		label {
			font-weight: 500;
		}

	}

	.checks {

		.checkbox {

			label {
				vertical-align: inherit;
				display: unset;
			}
		}

		a {
			color: #a39c8a !important;
			text-decoration: underline;

			&:hover {
				color: #fff !important;
			}
		}
	}


	.flow-btn {
		margin-left: 20px;
	}


}

.amount-options-list {
	margin: 20px 0;

	.amount-option-btn {
		.amount-or {
			text-align: center;
			margin: 15px 0;
			width: 100%;
			max-width: 305px;
		}

		.btn-default {
			width: 100%;
			max-width: 305px;
		}
	}

}