.widget {
	/* background: #3e4037;
	box-shadow: 0 0 10px #000; */
	padding: 10px 20px 20px;
	border: 1px solid var(--color-gray-dark) !important;
}

.widget-header {
	background-color: transparent;
	border: 0px solid var(--widget-border-color);
	padding: 10px;
	margin-bottom: 0;
	border-bottom: none;
	font-size: var(--h2-font-size);
	font-weight: var(--h2-font-weight);
	text-decoration: var(--h2-text-decoration);
	text-transform: var(--h2-text-transform);
	color: var(--widget-header-title-font-color);
	font-family: var(--title-font-family-main);
	color: #192f40;
	font-size: 28px;
	border-bottom: 1px dashed #192f40;
	padding: 0 0 15px;
	margin-bottom: 15px;
}

.widget-block {
	background-color: var(--widget-block-bg-color);
	padding: 0px;
	border: 0px solid var(--widget-border-color);


	.title {
		font-size: var(--h3-font-size);
		font-weight: var(--h3-font-weight);
		text-decoration: var(--h3-text-decoration);
		text-transform: var(--h3-text-transform);
		color: var(--widget-block-title-font-color);
		margin-bottom: 1rem;
	}

	.unit-map {
		width: 100%;
		height: 380px;
		position: relative;
	}
}